import React from 'react';
import { Press as PressType} from 'shared/types';

 

export const PressModal = ( props: PressType) => {

    const { title, desc, date } = props;

    return (
        <div className="Press-detail">
             <span className="date">{date}</span>
            <h3 className="heading h-2">{title}</h3> 
            <p> {desc} </p>   

         </div>
    )

}