import { SectionData, Section } from "shared/types";
import { SectionCard } from "./section-card";

type SectionsPorps = {
  data: SectionData[];
  currentSection: Section;
  navigateSection: (section: Section) => void;
};

export const Sections = (props: SectionsPorps) => {
  return (
    <div className="Sections">
      {props.data.map((section, i) => (
        <SectionCard
          key={i}
          index={i}
          section={section}
          navigateSection={props.navigateSection}
          currentSection={props.currentSection}
        />
      ))}
    </div>
  );
};
