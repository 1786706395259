import { Swiper, SwiperSlide } from "swiper/react";

type WhatWeDoType = {
  title: string;
  product: string[];
  image: string;
};

type WhatWeDoProps = {
  data: WhatWeDoType[];
};

export const WhatWeDo = (props: WhatWeDoProps) => {
  const deg = [-10, 0, -10, -10, 5];

  return (
    <div className="WhatWeDo container xl m-auto">
      <Swiper slidesPerView={"auto"} className="slider">
        {props.data.map((data, i) => (
          <SwiperSlide className="WhatWeDo-slide item" key={i}>
            <div className="circle" />
            <div className="proportion" />
            <div className="slide-content">
              <h3 className="heading h-2 m-0">{data.title} </h3>
              {data.product.map((product, i) => (
                <div key={i} className="product heading h-12">
                  {product}
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
