import { useState } from "react";

import sections from "data/sections.json";
import { SectionData } from "shared/types";
import { Link } from "react-router-dom";
import Social from "./social/social";
import { Modal } from "shared/modal";
import { LegalModal } from "./modal-legal";

type ModalType = "PRIVACY" | "COOKIE" | null;

type FooterState = {
  type: ModalType;
  isModalOpen: boolean;
};

export const Footer = () => {
  const currentLogo: any = {
    DISS: "logo-diss.png",
    BLST: "logo-blast.png",
    PNG: "logo-pangea.png",
    RC: "logo-rc.png",
    GOG: "logo-gog.png",
    BI: "logo-bi.png",
    GEM: "logo-gem.png",
    LP: "logo-lp.png",
    ST: "logo-stragog.jpg",
    BLBREP: "logo-bubble.png",
  };

  const [footerState, setFooterState] = useState<FooterState>({
    isModalOpen: false,
    type: null,
  });

  const toggleModal = () => {
    setFooterState({
      type: null,
      isModalOpen: !footerState.isModalOpen,
    });
  };

  const openModal = (type: ModalType) => {
    setFooterState({
      type: type,
      isModalOpen: true,
    });
  };

  return (
    <div className="Footer">
      <Social />

      <div className="footer-container">
        <div className="footer-nav">
          {sections.map((item: SectionData, i) => (
            <div key={i}>
              <Link to={`/${item.url}`}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/${currentLogo[item.section]}`
                  }
                  alt=""
                />
              </Link>
            </div>
          ))}
        </div>

        <div className="footer-content">
          <div className="copy">2022 @ GRUPPO MAGOG</div>

          <div className="legal">
            <span onClick={() => openModal("PRIVACY")}>Privacy</span>
            <span onClick={() => openModal("COOKIE")}>Cookie</span>
          </div>
        </div>
      </div>

      {footerState.isModalOpen && (
        <Modal close={toggleModal}>
          <LegalModal type={footerState.type} />
        </Modal>
      )}
    </div>
  );
};
