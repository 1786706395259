import {ProjectIntro} from 'shared/projects/project-shared/project-intro';
import {ProjectDesc} from 'shared/projects/project-shared/project-desc';
import {ProjectOutro} from 'shared/projects/project-shared/project-outro';
import {Numbers} from 'shared/numbers'; 
import { SectionData, Section} from 'shared/types';
import { ProjectVideo} from 'shared/projects/project-shared/project-video'
import {Bookstore} from './bookstore'; 


export const Lp = ( props : SectionData ) => {

    const videoUrl =  process.env.PUBLIC_URL + '/assets/lp/libropolis-spot.mp4';
    const link = props.link

    return (
        <div className="project color-white" style={{backgroundColor: props.bg}}>
            <ProjectIntro {...props} />
            <div className="container full m-auto">  
                <ProjectDesc {...props} />
                <ProjectVideo  { ...{url: videoUrl, link: link, alternate: true}} />
                <Numbers data={props.numbers} section={props.section as Section} />
                <Bookstore />
                <ProjectOutro {...props} />
            </div>
        </div>
    );
 
}  