import React from 'react';

type BackdropProps = {
    show: boolean;
    clicked: () => void;
}

const Backdrop = ( props : BackdropProps ) => {
  const { show, clicked } = props;

  return <div className={`Backdrop ${show ? 'open' : ''}`} onClick={clicked}></div>;
};

export default Backdrop;
