import data from "data/libropolis.json";

export const Bookstore = () => {
  return (
    <div className="container md m-auto">
      <h2 className="heading h-3 m-0 font-weight-medium"> {data.title} </h2>
      <h3 className="heading h-25 m-0 font-weight-thin"> {data.subtitle} </h3>

      <div className="bookstore">
        <div className="desc">
          <p dangerouslySetInnerHTML={{ __html: data.desc }} />
        </div>

        <div className="images">
          <img
            src={process.env.PUBLIC_URL + `/assets/lp/${data.image1}`}
            alt="libropolis"
          />
          <img
            src={process.env.PUBLIC_URL + `/assets/lp/${data.image2}`}
            alt="libropolis"
          />
        </div>
      </div>
    </div>
  );
};
