import { useEffect, useRef } from "react";
import { Section, Image as ImageType } from "shared/types";
import { gsap } from "gsap";
import { useWindowSize } from "hooks/useWindowSize";
import sectionData from "data/sections.json";
import Image from "shared/images";
import { Link } from "react-router-dom";
import Icon from "shared/icons";
import siteData from "data/site.json";

type NavigationProps = {
  section: Section;
  menuOpen: boolean;
  closeMenu: () => void;
};

export const Navigation = (props: NavigationProps) => {
  const [windowWidth, windowHeight] = useWindowSize();

  const navigation = useRef<HTMLDivElement>(null);
  const navigationCircleWhite = useRef<HTMLDivElement>(null);
  const navigationCircleBlack = useRef<HTMLDivElement>(null);
  const navigationContainer = useRef<HTMLDivElement>(null);

  const NavigationOpen = () => {
    const sectionsRef = document.querySelectorAll(".Navigation-sections__item");

    const size = Math.max(windowWidth, windowHeight) * 1.5;
    document.body.classList.add("oy-hidden");
    navigation?.current?.classList.add("open");

    gsap.set(navigationCircleWhite.current, {
      scale: 0,
      width: size,
      height: size,
    });
    gsap.to(navigationCircleWhite.current, {
      duration: 1,
      ease: "power2.out",
      scale: 1,
    });

    gsap.set(navigationCircleBlack.current, {
      scale: 0,
      width: size,
      height: size,
    });
    gsap.to(navigationCircleBlack.current, {
      duration: 1,
      delay: 0.25,
      ease: "power2.out",
      scale: 1,
    });

    gsap.set(navigationContainer.current, { y: 120 });
    gsap.to(navigationContainer.current, {
      duration: 1,
      delay: 0.5,
      ease: "power2.out",
      y: 60,
      opacity: 1,
    });

    sectionsRef.forEach((section, i) => {
      gsap.set(section, { y: 0, opacity: 0 });
      gsap.to(section, {
        duration: 0.75,
        delay: 0.5 + 0.1 * i,
        ease: "power2.out",
        y: -60,
        opacity: 1,
      });
    });
  };

  const NavigationClose = () => {
    document.body.classList.remove("oy-hidden");

    gsap.to(navigationContainer.current, {
      duration: 0.5,
      ease: "power2.out",
      y: 120,
      opacity: 0,
    });

    gsap.to(navigationCircleBlack.current, {
      duration: 0.5,
      delay: 0.35,
      ease: "power2.out",
      scale: 0,
    });
    gsap.to(navigationCircleWhite.current, {
      duration: 0.35,
      delay: 0.5,
      ease: "power2.out",
      scale: 0,
    });

    setTimeout(() => {
      navigation?.current?.classList.remove("open");
      props.closeMenu();
    }, 1000);
  };

  useEffect(() => {
    props.menuOpen ? NavigationOpen() : NavigationClose();
    // eslint-disable-next-line
  }, [props.menuOpen]);

  return (
    <div className="Navigation" ref={navigation}>
      <div className="Navigation-circle-white" ref={navigationCircleWhite} />
      <div className="Navigation-circle-black" ref={navigationCircleBlack} />

      <div className="Navigation-container">
        <div className="Navigation-container__inner" ref={navigationContainer}>
          <div className="Navigation-sections">
            {sectionData.map((section, i) => {
              if (section.section !== "FOO") {
                return (
                  <div key={i} className="Navigation-sections__item">
                    <Link to={section.url} onClick={NavigationClose}>
                      <Image
                        name={section.logo as ImageType}
                        title={section.title}
                      />
                    </Link>
                  </div>
                );
              }
              return "";
            })}
          </div>
          <div className="Navigation-content">
            <div className="Navigation-content__block">
              <h2 className="heading h-2">Il Gruppo</h2>
              {/* <div className="item">
              <Link to="/la-nostra-storia" onClick={NavigationClose}>
                La nostra storia
              </Link>
            </div> */}
              <div className="item">
                <Link to="/collabora" onClick={NavigationClose}>
                  Collabora con noi
                </Link>
              </div>
              <div className="item">
                <Link to="/organigramma" onClick={NavigationClose}>
                  Organigramma
                </Link>
              </div>
            </div>
            <div className="Navigation-content__block">
              <h2 className="heading h-2">Contatti</h2>
              <div className="item">
                Informazioni
                <br /> info@gruppomagog.it
              </div>
              <div className="item">
                Gruppo Editoriale MAGOG S.R.L
                <br />
                CF e P.IVA: 16594391001
              </div>
              <div className="item">Sede operativa: Parioli, Roma</div>
            </div>
            <div className="Navigation-content__block ">
              <h2 className="heading h-2">Social</h2>
              <div className="item social">
                <a
                  href={siteData.social.linkedin}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="iconLinkedin" className="lg fill-white ml-auto" />
                </a>
                <a
                  href={siteData.social.instagram}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    name="iconInstagram"
                    className="lg fill-white ml-auto"
                  />
                </a>
                <a
                  href={siteData.social.twitter}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="iconTwitter" className="lg fill-white ml-auto" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
