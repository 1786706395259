import { Images }  from 'assets/images';
import {Image as ImageType} from 'shared/types'

type ImageProps = {
  name: ImageType;
  title: string; 
 };

const Image = (props: ImageProps) => {
  const img = Images[props.name];
  return  <img src={img} alt={props.title} /> ;
};

export default Image;