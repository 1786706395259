import React from "react";

type ButtonProps = {
  children: any;
  className: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const Button = (props: ButtonProps) => {
  const { className, children, disabled, onClick } = props;

  return (
    <button
      className={`button ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
