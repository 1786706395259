import { Visit } from "shared/visit";
import { Section } from "shared/types";

type ProjectMobileType = {
  imageUrl: string;
  text: string;
  link: string;
  section: Section;
};

export const ProjectMobile = (props: ProjectMobileType) => {
  const { imageUrl, text, link, section } = props;

  return (
    <div className="project-mobile">
      <div className="content">
        <div className="image">
          <img src={process.env.PUBLIC_URL + `${imageUrl}`} alt="" />
        </div>
        <div className="desc" dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      <Visit
        {...{ link: link, style: section === "DISS" ? "black" : "white" }}
      />
    </div>
  );
};
