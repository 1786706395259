import { useRef,  memo } from "react";
import Icon from "shared/icons";
import siteData from "data/site.json";
 
const Social = () => {
  const socialSlider = useRef(null);

 

  return (
    <div className="footer-social" ref={socialSlider}>
      <a href={siteData.social.linkedin} target="blank">
        <Icon name="iconLinkedin" className="lg fill-white ml-auto" />{" "}
        <span>Linkedin</span>
      </a>
      <a href={siteData.social.instagram} target="blank">
        <Icon name="iconInstagram" className="lg fill-white ml-auto" />
        <span>Instagram</span>
      </a>
      <a href={siteData.social.twitter} target="blank">
        <Icon name="iconTwitter" className="lg fill-white ml-auto" />{" "}
        <span>Twitter</span>
      </a>
    </div>
  );
};

export default memo(Social);
