import React, {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Modal} from 'shared/modal';
import {PressModal} from './press-modal'  
import { Press as PressType} from 'shared/types';
import {trimStr} from 'utils/general';
import {Button} from 'shared/button';
import Icon from 'shared/icons';

 
type PressProps = {
    data: PressType[],
 }

export const Press = (props : PressProps) => {

    const { data  } = props;

    const [isModalOpen, setModalOpen ] = useState<boolean>(false);
    const [currentPress, setCurrentPress] = useState<any>(null);

    const closeModal = () => {
        setModalOpen(!isModalOpen);
        setCurrentPress(null);
    }

    const onPressClick = ( press : PressType ) => {
        setCurrentPress(press);
        setModalOpen(true);
    }
  
    return (
        <>
            <div className="Press">
                <h3 className="heading h-3 mb-3"> Comunicati</h3>            
            
                <Swiper slidesPerView={'auto'} className="slider">
                    {data.map( (press,i) => (
                        <SwiperSlide className="Press-slide" key={i}>  
                            <span className="date">{press.date}</span>
                            <h3 className="heading h-2">{press.title}</h3> 
                            <p> {trimStr(press.desc, 70)} </p>
                            <Button className="circle md bg-white" onClick={() => onPressClick(press)} > <Icon name="arrowRight" /></Button>
                        </SwiperSlide>
                    )
                    )}
                </Swiper>

            </div>

            {isModalOpen && <Modal close={closeModal}> <PressModal {...currentPress} /></Modal>}

        </>
    )

}
 