import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as iconLinkedin } from "./icon-linkedin.svg";
import { ReactComponent as IconInstagram } from "./icon-intragram.svg";
import { ReactComponent as IconTwitter } from "./icon-twitter.svg";
import { ReactComponent as ArrowExternalLink } from "./arrow-external-link.svg";

export const Icons = {
  close: Close,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  iconLinkedin: iconLinkedin,
  iconInstagram: IconInstagram,
  iconTwitter: IconTwitter,
  arrowExternalLink: ArrowExternalLink,
};
