 
import {Visit} from 'shared/visit';

type VideoProps = {
    url: string;
    link: string;
    alternate: boolean; 
}

export const ProjectVideo = (props : VideoProps) => {

    return (
      
        <div className={ `project-video ${props.alternate ? 'alternate' : ''}`}>
            <div className="video">
                <video controls>
                    <source src={props.url}  type="video/mp4" />
                </video>
            </div>

            <Visit { ...{link: props.link, style: 'white'}} />

            

        </div>
    

    );

}