import LogoGog from "./logo-gog.png";
import LogoGem from "./logo-gem.png";
import LogoBi from "./logo-bi.png";
import LogoLp from "./logo-lp.png";
import LogoRc from "./logo-rc.png";
import LogoDiss from "./logo-diss.png";
import LogoBlast from "./logo-blast.png";
import LogoPangea from "./logo-pangea.png";
import LogoStragog from "./logo-stragog.jpg";
import LogoBubble from "./logo-bubble.png";

export const Images = {
  logoDiss: LogoDiss,
  logoBlast: LogoBlast,
  logoPangea: LogoPangea,
  logoGog: LogoGog,
  logoGem: LogoGem,
  logoBi: LogoBi,
  logoLp: LogoLp,
  logoRc: LogoRc,
  logoStragog: LogoStragog,
  logoBubble: LogoBubble,
};
