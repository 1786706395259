
import {SectionData } from 'shared/types';


export const ProjectDesc = (props : SectionData) => {

    return (
        <div className="container md m-auto">
          <div className="project-desc"> 
            <div className="desc heading h-2"  dangerouslySetInnerHTML={{__html:props.longDesc}} />
            {/* <div className="visit">
                <a href={props.link} target="blank">
                  <Button className="circle xl bg-white">
                    <Icon name="arrowExternalLink" className="lg" />
                  </Button>
                </a>
            </div> */}
          </div>
 
        </div>
     
    );


}