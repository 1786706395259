import { Swiper, SwiperSlide } from "swiper/react";
import siteData from "data/site.json";

export const Story = () => {
  const stories = siteData.story;

  return (
    <div className="container full m-auto bg-black color-white">
      <div className="Story">
        <div className="desc">
          <h2 className="m-auto heading h-3 title-font">La nostra storia</h2>
          <h3 className="m-auto heading h-2">MAGOG</h3>
        </div>
        <div className="slider">
          <Swiper slidesPerView={"auto"}>
            {stories.map((story, i) => (
              <SwiperSlide className="Story-slide" key={i}>
                <h3 className="heading h-2">{story.title}</h3>
                <p> {story.desc} </p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
