import Icon from 'shared/icons';
import { Button } from 'shared/button'

type VisitProps = {
    link: string;
    style: 'white' | 'black';
}

export const Visit = (props: VisitProps) => {

    return (
        <div className={`visit ${props.style}`}>
            <a href={props.link} target="blank">
                <Button className="circle">
                    <Icon name="arrowExternalLink" className="lg" />
                </Button>
            
                <span>  VAI AL SITO </span>
            </a>
        </div>
    )

}