import data from "data/home.json";
import sectionData from "data/sections.json";
import { Intro } from "shared/home/intro";
import { WhatWeDo } from "shared/home/whatWeDo";
import { Numbers } from "shared/numbers";
import { Phrases } from "shared/home/phrases";
import { Press } from "shared/home/press";
import { Sections } from "shared/home/sections";
import { Section } from "shared/types";
import Talk from "./talk";

type HomeProps = {
  currentSection: Section;
  navigateSection: (section: Section) => void;
};

export const Home = (props: HomeProps) => {
  return (
    <div className="Home">
      <div className="container full m-auto">
        <Intro data={data.intro} />
        <WhatWeDo data={data.whatWeDo} />
        <Numbers data={data.numbers} section="MAGOG" />
        <Phrases data={data.phares} />
        <Press data={data.press} />
      </div>
      <Talk />
      <Sections
        data={sectionData}
        navigateSection={props.navigateSection}
        currentSection={props.currentSection}
      />
    </div>
  );
};
