
type ProjectImageType = {
    imageUrl: string;
    text: string;
}

export const ProjectImage = (props: ProjectImageType) => {

    const {imageUrl, text} = props;

    return (
 
        <div className="project-image">
            <div className="desc" dangerouslySetInnerHTML={{__html:text}} /> 
            <div className="image">  <img src={process.env.PUBLIC_URL + `${imageUrl}`} alt="" />  </div>
         </div>
    );

}
