 import { Swiper, SwiperSlide } from 'swiper/react';
 

type Phrase = {
    title: string;
    desc: string;
}
 
type PhrasesProps = {
    data: Phrase[],
 }

export const Phrases = (props : PhrasesProps) => {

    const { data } = props;
 

    return (
        <div className="Phrases">

            <Swiper 
                 slidesPerView={'auto'} 
                 className="slider" 
            >
                {data.reverse().map( (phrase,i) => (
                    <SwiperSlide className="Phrase-slide" key={i}>  
                        <h3 className="heading h-3 m-0 title-font">{phrase.title}</h3> 
                        <p dangerouslySetInnerHTML={{__html:phrase.desc}} /> 
                    </SwiperSlide>
                 )
                )}
             
            </Swiper>

          
        </div>
    )

}
 