import legalData from "data/legal.json";

type ModalType = "PRIVACY" | "COOKIE" | null;

type LegalModalProps = {
  type: ModalType;
};

export const LegalModal = (props: LegalModalProps) => {
  if (!props.type) {
    return <></>;
  }

  return (
    <div
      className="legal-modal"
      dangerouslySetInnerHTML={{ __html: legalData[props.type] }}
    />
  );
};
