import {SectionData } from 'shared/types';

export const ProjectOutro = (props: SectionData) => {

    return (
        <div className="container xs m-auto">
          <div className="project-outro heading h-2 title-font"  dangerouslySetInnerHTML={{ __html: props.outro }}  />
        </div>
    );

}