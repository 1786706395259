import { ProjectIntro } from "shared/projects/project-shared/project-intro";
import { ProjectDesc } from "shared/projects/project-shared/project-desc";
import { ProjectOutro } from "shared/projects/project-shared/project-outro";
import { Numbers } from "shared/numbers";
import { SectionData, Section } from "shared/types";

import { ProjectImage } from "shared/projects/project-shared/project-image";
import { ProjectMobile } from "shared/projects/project-shared/project-mobile";
import { Visit } from "shared/visit";
import { Swiper, SwiperSlide } from "swiper/react";

export const Pangea = (props: SectionData) => {
  const projectImageData = {
    imageUrl: "/assets/pangea/image.jpg",
    text: "",
  };
  const projectMobileData = {
    imageUrl: "/assets/pangea/mobile.png",
    text: "",
    link: props.link,
    section: props.section as Section,
  };

  const collana = {
    title: "La collana",
    link: "https://www.pangea.news/negozio/",
    image: "/assets/pangea/collana.png",
    desc1:
      "Nell'era dell'editoria omeopatica, sbocciano ancora editori che ripudiano la somministrazione letteraria a piccole dosi. È il caso di Magog - nome arcano, tra il fuoco e il vento - progetto di spirito aristocratico, corsaro, controcorrente, più che una casa editrice un'avventura editoriale. Nata sotto il segno di un libro introvabile, a seguito di nottambule ricerche ed entusiasmi cuciti sull'orlo della luce, la collana Magog, dal 2022, germoglia nel ventre di Pangea (www.pangea.news), rivista avventuriera di Cultura & Idee fondata nel 2017 e diretta da Davide Brullo - poeta, giornalista, scrittore - in collaborazione con Fabrizia Sabbatini - editor di GOG - disegnando nuove rotte sulla mappa che ne guida la ricerca di piccoli tesori letterari.   ",
    desc2:
      "Ciascun volume - unico, numerato a mano in copie da uno a cento - è frutto d'ispirazione, epifania, rivelazione improvvisa. Tascabili come un breviario e affilati come una katana, i libri Magog alla piattezza del pigmento preferiscono il rigore essenziale del bianco e nero, la copertina come veste sacra su cui imprimere un sigillo, tratteggiato per ogni titolo dall'artista Angelo Borgese, che con cieca fede nel pudore dell'eleganza muta la collana in collezione.",
    desc3:
      "Magog è il patto di sangue e il voto infranto di chi, condannato alle parole, rifugge salotti e velleità di velluto, al cuore devoto e infedele dei suoi avventori si offre con abbonamenti al buio - di diversa audacia, .corsaro' per gli approcci più timidi e .aristocratico' per quelli più ardimentosi - dedicando ai suoi lettori una ulteriore selezione di testi imprevedibili, inafferrabili (per abbonarsi si passa esclusivamente per il sito di Pangea). ",
  };

  const teatro = {
    title: "Pangea a Teatro",
    desc: "Fuori dal canonico sistema distributivo, il progetto editoriale si fa teoria di formule eccentriche, fino all'approdo in teatro, dove il libro muta in libretto, scena, verso attoriale, storia narrata da parole che avvampano. Con la complicità del Teatro Basilica di Roma - diretto da Daniela Giovanetti e Alessandro Di Murro - Magog ha inaugurato, nel 2023, una breve stagione di lezioni-spettacolo dal tema “Ho scelto l'oppio. Storie di donne estreme”, aprendo il sipario al 'femminile' che turba e sconvolge la grande letteratura, all'anormalità, alla foga, all'estro dissennato necessari a forgiare la norma narrativa.",
    imgs: ["teatro-0.jpg", "teatro-1.jpg", "teatro-2.jpg", "teatro-3.jpg"],
  };

  return (
    <div className="project color-white" style={{ backgroundColor: props.bg }}>
      <ProjectIntro {...props} />
      <div className="container full m-auto">
        <ProjectDesc {...props} />
        <ProjectImage {...projectImageData} />
        <ProjectMobile {...projectMobileData} />
        <Numbers data={props.numbers} section={props.section as Section} />
      </div>

      <div className="pangea-extra">
        <div className="container full m-auto">
          <div className="pangea-collana">
            <div className="title heading h-3 font-weight-medium">
              {collana.title}
              <span>diretta da Davide Brullo e Fabrizia Sabbatini</span>
            </div>
            <div className="image">
              <Visit {...{ link: collana.link, style: "white" }} />
            </div>
            <div className="desc">
              <p>{collana.desc1}</p>
              <p>{collana.desc2}</p>
              <p>{collana.desc3}</p>
            </div>
            <div className="extra">
              <img src={collana.image} alt="" />
            </div>
          </div>

          <div className="pangea-teatro">
            <div className="title heading h-3 font-weight-medium">
              {teatro.title}
            </div>

            <div className="desc">
              <p>{teatro.desc}</p>
            </div>
            <div className="extra">
              <Swiper slidesPerView={"auto"} className="slider-pangea">
                {teatro.imgs.map((img, i) => (
                  <SwiperSlide
                    key={i}
                    className={`${i === 0 ? "vertical" : "horizontal"}`}
                  >
                    <img src={`/assets/pangea/${img}`} alt="Pangea Teatro" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <ProjectOutro {...props} />
    </div>
  );
};
