 import {Section, Number} from 'shared/types';
   
 
type NumbersProps = {
    data: Number[],
    section :Section
}

export const Numbers = (props : NumbersProps) => {

 
    const numbers = props.data;
    
    return (
        <div className={ `Numbers num-${numbers.length}`}>
            { numbers.map( (number,i) => (
            <div className={`number pos-${i+1}`} key={i}>  
                <span className={`amount ${props.section}`}>{number.amount}</span>
                {number.desc} 
            </div>
            ) )}
        </div>
    )

}
 