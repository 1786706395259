import { useEffect, useRef } from "react";
import Backdrop from "./backdrop";
import Icon from "shared/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { gsap } from "gsap";

type ModalProps = {
  show?: boolean;
  close: Function;
  showCloseButton?: boolean;
  title?: string;
  children: any;
  size?: string;
};

export const Modal = (props: ModalProps) => {
  const { close, showCloseButton = true, title, children, size } = props;
  const modal = useRef(null);

  const handleClose = () => {
    gsap.to(modal.current, {
      duration: 0.1,
      scale: 0.9,
      opacity: 0,
      onComplete: () => close(),
    });
  };

  const handleKey = (e: KeyboardEvent) => {
    e.keyCode === 27 && handleClose();
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    document.body.classList.add("oy-hidden");
    gsap.to(modal.current, {
      duration: 0.25,
      ease: "power2.out",
      scale: 1,
      opacity: 1,
    });
    return () => {
      window.removeEventListener("keydown", handleKey);
      document.body.classList.remove("oy-hidden");
    };
  }, [handleKey]);

  return (
    <>
      <Backdrop show={true} clicked={handleClose} />
      <div ref={modal} className={`Modal open ${size ? size : ""}  }`}>
        <div className="Modal-header">
          {title ? <h2 className="heading h-15">{title}</h2> : null}
          {showCloseButton ? (
            <div className="button basic ml-auto" onClick={() => handleClose()}>
              <Icon name="close" />
            </div>
          ) : null}
        </div>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
          }}
        >
          <div className="Modal-content">{children}</div>
        </PerfectScrollbar>
      </div>
    </>
  );
};
