import { useState, useCallback, useEffect } from "react";
import { Section } from "shared/types";
import { ReactComponent as Logo } from "assets/images/logo-magog.svg";
import { Link } from "react-router-dom";

type HeaderProps = {
  section: Section;
  menuOpen: boolean;
  toggleMenu: () => void;
  navigateSection: (section: Section) => void;
};

const currentLogo = {
  MAGOG: "gruppo.png",
  DISS: "logo-diss.png",
  BLST: "logo-blast.png",
  PNG: "logo-pangea.png",
  RC: "logo-rc.png",
  GOG: "logo-gog.png",
  BI: "logo-bi.png",
  GEM: "logo-gem.png",
  LP: "logo-lp.png",
  BLBREP: "logo-bubble.png",
};

export const Header = (props: HeaderProps) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const SCROLL = {
    scrollTop: 0,
    delta: 0,
  };

  const handleScroll = useCallback(() => {
    SCROLL.delta = window.scrollY > SCROLL.scrollTop ? -1 : 1;
    SCROLL.scrollTop = window.scrollY;
    if (SCROLL.scrollTop > 80) {
      setIsScrolled(true);
      SCROLL.delta > 0 ? setIsVisible(true) : setIsVisible(false);
    } else {
      setIsScrolled(false);
      setIsVisible(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      className={`Header ${isVisible ? "visible" : ""} ${
        isScrolled ? "scrolled" : ""
      }  ${props.menuOpen ? "menuOpen" : ""} `}
    >
      <Link to="/" onClick={() => props.navigateSection("MAGOG")}>
        <div className="brand">
          <Logo />
        </div>
      </Link>
      <div className="menu-toggle" onClick={() => props.toggleMenu()}>
        <div className={`hamburger ${props.menuOpen ? "open" : ""}`}>
          <div className="line bread-top"></div>
          <div className="line bread-bottom"></div>
          <div className="dish"></div>
        </div>
      </div>
      <div className="brand-current">
        {props.section && (
          <img
            src={
              process.env.PUBLIC_URL + `/assets/${currentLogo[props.section]}`
            }
            alt=""
          />
        )}
      </div>
    </div>
  );
};
