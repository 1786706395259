import { SectionData } from "shared/types";

export const ProjectIntro = (props: SectionData) => {
  const { title, desc, cover, section } = props;

  return (
    <div className="Sections ">
      <div className={`Section ${section}`}>
        <div className="section-content">
          <div className="image">
            <img src={process.env.PUBLIC_URL + `/assets/${cover}`} alt="" />
          </div>

          <div className="meta">
            <h2 className="heading h-4 m-auto line-height-1 title-font">
              {title}
            </h2>
            <h3 className="heading h-3 m-auto line-height-1 font-weight-thin">
              {desc}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
