type IntroType = {
    title: string;
    subtitle: string;
}

type PhrasesProps = {
    data: IntroType,
 }

 export const Intro = (props : PhrasesProps) => {

    const { title, subtitle } = props.data;
 
    return (
        <div className="Intro">

            <div className="container m-auto p-0">
                <h1 className="title-font"> {title} </h1>
                <p>
                    {subtitle}
                </p>
            </div>    

 
        </div>
    )

}
 