 import { useScrollPercentage } from 'react-scroll-percentage'

import {SectionData, Section} from 'shared/types';
import {Button} from 'shared/button';
import Icon from 'shared/icons';
import { Link } from "react-router-dom";
  

type SectionCardPros = {
    index: number;
    section: SectionData;
    currentSection: Section;
    navigateSection: (section : Section ) => void;
}

 
export const SectionCard = ( props : SectionCardPros) => {
    const {section, bg, bgPrev, title, desc, url, cover} = props.section;
    const index = props.index;
 

    const [ref, percentage] = useScrollPercentage({
        threshold: 0,
     })

    const direction = index % 2 === 0 ? 1 : -1;
    const rotation =  ( 1 - percentage ) * 15 * direction;
  

    const circle = { 
        backgroundColor: bg,
        transform: `translate(-50%, 0) scale(  ${1 + percentage})`,
    }
    const image = {
        transform: ` rotate(${rotation}deg)`
    }

    const meta = {
        opacity: Math.min(percentage * 2, 1)
    }

    const content = {
        transform: `translate( 0, -${200 * percentage}px)`
    }
 
    const background = {
        backgroundColor: bg
    }
    const prevBackground = {
        backgroundColor: bgPrev
    }
  
 
    return (
        <div className={`Section ${section}`} style={prevBackground} ref={ref} data-section={section}>
            <div className="section-circle" style={circle } ></div>  
            <div className="background" style={background} ></div> 

            { section !== "FOO" && (
            <div className="section-content" style={content}> 
                    <div className="image" style={image}>
                        <img src={ process.env.PUBLIC_URL + `/assets/${cover}` } alt="" /> 
                    </div>

                    <div className="meta" style={meta}>
                        <h2 className="heading h-5 m-auto line-height-1 title-font" onClick={() => props.navigateSection(section as Section)}>{title} </h2>
                        <div className="desc">{desc}</div>

                        <Link to={url}> 
                             <Button className="circle lg bg-black m-auto" > <Icon name="arrowRight"  className="fill-white" /></Button>   
                      </Link>
                       
                    </div>
            </div>
            )}
         </div>
    );
}
