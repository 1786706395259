import { Icons } from "assets/icons";
import { Icons as IconType } from "shared/types";

type IconProps = {
  name: IconType;
  className?: any;
};

const Icon = (props: IconProps) => {
  const SVG = Icons[props.name];
  return (
    <div className={`icon ${props.className}`}>
      <SVG />
    </div>
  );
};

export default Icon;
