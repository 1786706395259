import { useState, useEffect } from "react";
import { Header } from "./shared/header";
import { Navigation } from "./shared/navigation";
import { Footer } from "./shared/footer";
import { Section } from "./shared/types";
import { Route, useLocation, Routes } from "react-router-dom";
import "swiper/css";
import "react-perfect-scrollbar/dist/css/styles.css";

import sectionData from "data/sections.json";
import { Home } from "./shared/home";
import { Diss } from "./shared/projects/diss";
import { Gog } from "./shared/projects/gog";
import { Bi } from "./shared/projects/bi";
import { Gem } from "./shared/projects/gem";
import { Lp } from "./shared/projects/lp";
import { Rc } from "./shared/projects/rc";
import { Story } from "./shared/story";
import { Organization } from "shared/organization";
import Collabora from "shared/collabora";
import Pangea from "shared/projects/pangea";
import Blast from "shared/projects/blast";
import { Stragog } from "shared/projects/stragog";
import Bubble from "shared/projects/bubble";

function App() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<Section>(null);

  const [background, setBackground] = useState<string>("");

  const { pathname } = useLocation();

  const onChangeSection = () => {
    switch (pathname) {
      case "/":
        return "MAGOG";
      case "/dissipatio":
        return "DISS";
      case "/gog-edizioni":
        return "GOG";
      case "/bestirio-degli-italiani":
        return "BI";
      case "/corsi-di-formazione-gem":
        return "GEM";
      case "/festival-libropolis":
        return "LP";
      case "/rivista-constrasti":
        return "RC";
      case "/bubble-report":
        return "BLBREP";
      default:
        return "MAGOG";
    }
  };

  useEffect(() => {
    if (pathname === "/" && currentSection !== "MAGOG") {
      const section = document.querySelector(
        `[data-section="${currentSection}"]`
      );
      const top = section?.getBoundingClientRect().top;
      window.scrollTo(0, top || 0);
    } else {
      window.scrollTo(0, 0);
    }

    const current = onChangeSection();

    const bg = getCurrentBg(current);
    setCurrentSection(current);
    setBackground(bg);
    // eslint-disable-next-line
  }, [pathname]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const navigateSection = (section: Section) => {
    // setCurrentSection(section);
  };

  const getCurrentBg = (current: Section) => {
    if (current) {
      if (current === "MAGOG") {
        return "#7e00e4";
      }

      const section = sectionData.filter(data => data.section === current);

      if (section) {
        return section[0].bg;
      }

      return "#050505";
    }

    return "#7e00e4";
  };

  return (
    <div
      className={`App ${currentSection}`}
      style={{ backgroundColor: background }}
    >
      <Header
        section={currentSection}
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        navigateSection={navigateSection}
      />
      <Navigation
        section={currentSection}
        closeMenu={closeMenu}
        menuOpen={menuOpen}
      />

      <Routes>
        <Route path="/organigramma" element={<Organization />} />
        <Route path="/la-nostra-storia" element={<Story />} />

        <Route
          path="/festival-libropolis"
          element={<Lp {...sectionData[5]} />}
        />

        <Route path="/bubble-report" element={<Bubble {...sectionData[4]} />} />
        <Route path="/blast" element={<Blast {...sectionData[3]} />} />
        <Route
          path="/rivista-constrasti"
          element={<Rc {...sectionData[2]} />}
        />
        <Route path="/pangea" element={<Pangea {...sectionData[1]} />} />
        <Route path="/dissipatio" element={<Diss {...sectionData[0]} />} />
        <Route path="/collabora" element={<Collabora />} />
        <Route
          path="/"
          element={
            <Home
              navigateSection={navigateSection}
              currentSection={currentSection}
            />
          }
        />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
