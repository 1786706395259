import { ProjectIntro } from "shared/projects/project-shared/project-intro";
import { ProjectDesc } from "shared/projects/project-shared/project-desc";
import { ProjectOutro } from "shared/projects/project-shared/project-outro";
import { Numbers } from "shared/numbers";
import { SectionData, Section } from "shared/types";

import { ProjectImage } from "shared/projects/project-shared/project-image";
import { ProjectMobile } from "shared/projects/project-shared/project-mobile";

export const Blast = (props: SectionData) => {
  const projectImageData = {
    imageUrl: "/assets/blast/image.jpg",
    text: "",
  };
  const projectMobileData = {
    imageUrl: "/assets/blast/mobile.png",
    text: "",
    link: props.link,
    section: props.section as Section,
  };

  return (
    <div className="project color-black" style={{ backgroundColor: props.bg }}>
      <ProjectIntro {...props} />
      <div className="container full m-auto">
        <ProjectDesc {...props} />
        <ProjectImage {...projectImageData} />
        <ProjectMobile {...projectMobileData} />
        <Numbers data={props.numbers} section={props.section as Section} />
      </div>

      <ProjectOutro {...props} />
    </div>
  );
};
