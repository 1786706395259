import siteData from "data/site.json";

export const Organization = () => {
  const organinizations = siteData.organizations;

  return (
    <div className="container full m-auto bg-black color-white">
      <div className="Organization">
        <div className="d-flex flex-column">
          <h2 className="m-auto heading h-3 title-font">Organigramma</h2>
          <h3 className="m-auto heading h-2">MAGOG</h3>
        </div>
        <div className="grid-container col-3 mt-3">
          {organinizations.map((org, i) => (
            <div key={i} className="">
              <h2 className="heading h-2 m-0">{org.title}</h2>{" "}
              <h3 className="heading h-12 m-0">{org.subtitle}</h3>{" "}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
