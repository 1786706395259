import { ProjectIntro } from "shared/projects/project-shared/project-intro";
import { ProjectDesc } from "shared/projects/project-shared/project-desc";
import { ProjectOutro } from "shared/projects/project-shared/project-outro";
import { Numbers } from "shared/numbers";
import { SectionData, Section } from "shared/types";
import { ProjectImage } from "shared/projects/project-shared/project-image";
import { ProjectMobile } from "shared/projects/project-shared/project-mobile";
import { Visit } from "shared/visit";
import { Swiper, SwiperSlide } from "swiper/react";

export const Diss = (props: SectionData) => {
  const projectImageData = {
    imageUrl: "/assets/id/image.jpg",
    text: "<b>Testata giornalistica</b> registrata al Tribunale di Roma n.117/2019 del 19.09.2019.",
  };
  const projectMobileData = {
    imageUrl: "/assets/id/mobile.png",
    text: "",
    link: props.link,
    section: props.section as Section,
  };

  const nucleo = {
    title: "Il Nucleo Operativo",
    desc1:
      "Dissipatio vive in una doppia dimensione, la sua attività interseca un piano esoterico e uno essoterico, l'invisibile il visibile, ciò che è segreto, intimo, rituale, e ciò invece che è rivelato, manifesto, accessibile, una dimensione che è per pochi, e una dimensione per tutti gli altri. È la regola primordiale della dissimulazione, il mistero che avvolge una cerchia ristretta di uomini e donne, bestie e dèi, che hanno deciso di vivere nell'ombra della luce, e di cercare l'alba dentro l'imbrunire.",
    desc2:
      " La verità non puoi comprarla in edicola a soli 2 euro o 2 euro e 50. Le informazioni, nel vero senso della parola, si pesano come l'oro, oppure restano confidenziali, gratuite, dunque per pochissimi. Tuttavia quelle informazioni che pesano veramente, che si possono trovare ancora nei quotidiani o ancora nei mezzi radiofonici o televisivi, tutte le mattine, rappresentano forse l'1 per cento della totalità giornaliera delle notizie pubblicate. Il restante 99 per cento è cartastraccia. La specificità di Dissipatio è quella di individuare quel punto percentuale, di scindere l'essenza delle cose accadono e il superfluo degli accadimenti, di muoversi tra le nuvole e il sottosuolo, di leggere e scrivere tra le righe, tra i segni, tra i simboli, di parlare il linguaggio del potere, e infine di svelare le meccaniche profonde che muovono lo Spirito, il Tempo, lo Spazio.",
    desc3:
      "Dissipatio non è un portale, né un testata, tantomeno una piattaforma, bensì è una cellula proiettata    nell'etere e sulla terra, in formato digitale e analogico, nello spazio e nel tempo. Ogni cellula possiede un nucleo. Come in biologia, anche da noi il nucleo (operativo) compie due funzioni cruciali per la cellula. In primo luogo porta alla cellula l'informazione ereditaria, in secondo luogo dirige le attività della cellula.",

    link: "https://www.dissipatio.it/nucleo-operativo/",
    imgs: ["nucleo-dispaccio.png", "nucleo-collana.jpg", "nucleo-martedi.jpg"],
  };

  const collana = {
    title: "I Fuori collana di Dissipatio",
    desc: "E' la collana editoriale di Dissipatio nata in memoria di Claudio Chianese, nostra firma storica, che pubblica libri di autori defunti o viventi su tematiche al confine tra politica e metapolitica, spionaggio e letteratura, storia e filosofia, spirito, spazio e tempo. ",
    link: "https://www.dissipatio.it/negozio/",
    image: "/assets/id/collana.png",
  };

  return (
    <div className="project color-black" style={{ backgroundColor: props.bg }}>
      <ProjectIntro {...props} />
      <div className="container full m-auto">
        <ProjectDesc {...props} />
        <ProjectImage {...projectImageData} />
        <ProjectMobile {...projectMobileData} />
        <Numbers data={props.numbers} section={props.section as Section} />
      </div>

      <div className="id-extra">
        <div className="container full m-auto">
          <div className="id-nucleo">
            <div className="title heading h-3 font-weight-medium">
              {nucleo.title}
            </div>
            <div className="image">
              <Visit {...{ link: nucleo.link, style: "white" }} />
            </div>
            <div className="desc">
              <p>{nucleo.desc1}</p>
              <p>{nucleo.desc2}</p>
              <p>{nucleo.desc3}</p>
            </div>
            <div className="extra">
              <Swiper slidesPerView={"auto"} className="slider-nucleo">
                {nucleo.imgs.map((img, i) => (
                  <SwiperSlide
                    key={i}
                    className={`${i === 1 ? "vertical" : "horizontal"}`}
                  >
                    <img src={`/assets/id/${img}`} alt="Nucleo Operativo" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="id-collana">
            <div className="title heading h-3 font-weight-medium">
              {collana.title}
            </div>
            <div className="image">
              <Visit {...{ link: collana.link, style: "white" }} />
            </div>
            <div className="desc">
              <p>{collana.desc}</p>
            </div>
            <div className="extra">
              <img src={collana.image} alt="" />
            </div>
          </div>

          <ProjectOutro {...props} />
        </div>
      </div>
    </div>
  );
};
