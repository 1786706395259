import Marquee from "react-fast-marquee";

export const Talk = () => {
  const data = [
    "prima-comunicazione.png",
    "dagospia.png",
    "la-republica.png",
    "ag.png",
    "il-giornale.png",
    "avvenire.png",
    "il-foglio.png",
  ];

  return (
    <>
      <div className="container full m-auto">
        <h3 className="heading h-3 mb-3"> Parlano di noi
        </h3>
      </div>
      <div className="talk">
        <Marquee>
          {data.map(item => {
            return (
              <div className="talk__item">
                <img
                  src={process.env.PUBLIC_URL + `/assets/talk/${item}`}
                  alt=""
                />
              </div>
            );
          })}
        </Marquee>
      </div>
    </>
  );
};
